import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CircularProgress from '@material-ui/core/CircularProgress';
import { useAuthState } from "@bamboobox/b2logincheck";
import CustomCard from './../../components/card';
import { makeStyles } from '@material-ui/core/styles';
import { useStyles } from './../../constans'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Radio from '@mui/material/Radio';
import './styles.css';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import "./toast.css";
import Alert from '@mui/material/Alert';


function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function Integration(props) {
  const userDetails = useAuthState();
  const tenantId = userDetails.user.tenantId;
  const alert_access = userDetails?.permissions?.some(p => p?.resourceName === 'alert_integration_access')

  const navigate = useNavigate();
  const classes = useStyles();
  const [leadfeederDialog, setLeadFeederDialog] = useState(false);
  const [hasApiKey, setHasApiKey] = useState("true")
  const [allWebsites, setAllWebsites] = useState([]);
  const [apiKeyValue, setApiKeyValue] = useState();
  const [selectedWebsite, setSelectedWebsite] = useState([])
  const [selectedWebsiteIds, setSelectedWebsiteIds] = useState([])
  const [intenttoolsIntegrated, setIntentToolsIntegrated] = useState('')
  const [notifications,setNotifications]=useState([])
  const [ispresent,setIspersent] = useState([])
	const [isLoading,setIsLoading] = useState(true);
  
  toast.configure({
    autoClose: 6000,
    draggable: true,
    hideProgressBar: false,
    position: toast.POSITION.BOTTOM_LEFT,
    icon: false,
});

async function fetchDat1() {
  try {
  const postData = { "tenantId": tenantId, "tool": "CDP", "keys": ["removeScreen"] }
  // console.log("postData from fetchData", postData, props);
  const TCRes = await fetch(`${window._env_.TENANTCONFIG_API}/tenant/get-tenant-config`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(postData),
    credentials: 'include'
  })
  setIsLoading(false)
  const TCResJSON = await TCRes.json()
  console.log(props.titlem,TCResJSON);
  // console.log("Data OF", TCResJSON)

    if(TCResJSON[0]&&TCResJSON[0].configVal){
      console.log("sccreen",TCResJSON[0]?.configVal.split(","))
      setIspersent(TCResJSON[0]?.configVal.split(","))
    }
  
} catch (error) {
    console.error("err"+error);
}
}

  const handleLeadFeederClose = (event) => {
    setLeadFeederDialog(false)
    // setHasApiKey("false")
    setAllWebsites([])
    setApiKeyValue()
    setSelectedWebsite([])
    setSelectedWebsiteIds([])
  }

  const getNotifications=()=>{
    fetch(`${window._env_.AUTH_URL_LOGIN}/notifications/get-notifications`, {
      method: "GET",
      credentials: "include",
    })
      .then((res) => res.json())
      .then((json) => {
        console.log("api response",json);
        if (json.success) {
          setNotifications(json.data);
        }
      }).catch((error)=>{
        console.log("notification error",error);
      });
  }

  useEffect(()=>{
    fetchDat1()
    getNotifications()
  },[])

  // const handleRadioChange = (event) => {
  //   console.log("event.target.value", event.target.value)
  //   setHasApiKey(event.target.value)
  // }

  const handleLeadSave = async (event) => {
    
    // console.log("allWebsitess", allWebsites)
    // console.log("selectedWebsite", selectedWebsite)
    // console.log("selectedWebsiteIds", selectedWebsiteIds)
    // console.log("apiKeyValue", apiKeyValue)
    let datatoSend = {
      "apiToken": apiKeyValue,
      "accountIds": selectedWebsiteIds,
    }

    const response = await fetch(`${window._env_.LEADFEEDER_GOLDENCOPY}/accounts/save-token`, {

			method: 'POST',
      // mode: 'cors',
			headers: {
        // 'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
			},
			body: JSON.stringify(datatoSend),
      credentials: 'include',
    })

    const result = await response.json()

    console.log("result from 90", result);

    handleLeadFeederClose()

    if(result){

      if(intenttoolsIntegrated.search('Leadfeeder') === -1){
        let tenantConfigVal = intenttoolsIntegrated.length ? `${intenttoolsIntegrated},Leadfeeder` : `Leadfeeder`
        const res_settenantConfig = await fetch(`${window._env_.TENANTCONFIG_API}/tenant/set-tenant-config`, {
          method: "POST",
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            "tenantId": `${tenantId}`,
            "tool": "CDP",
            "configArray": [{
                "tenantConfigKey":"Intent", 
                "tenantConfigValue": tenantConfigVal 
            }]
          }),
          credentials: 'include'
        }) 
        setIntentToolsIntegrated(tenantConfigVal)
        console.log("res_setTenantConfig", res_settenantConfig);

    }

      const response = await fetch(`${window._env_.LEADFEEDER_GOLDENCOPY}/sync/bulk-sync?tenantId=${tenantId}`, {


        method: 'GET',
        credentials: 'include'

      })

      const result = response.json();

      console.log("result from 102", result);
      
        toast.success(<Alert severity="success">data synced successfully</Alert>, {
          closeOnClick: true,
          autoClose: true,
          closeButton: true,
          newestOnTop: true
      })
      


    }
  }

  const handleLeadAuthenticate = async (event) => {
    // console.log(`${window._env_.LEADFEEDER_GOLDENCOPY}/accounts/get-all-accounts?apiToken=${apiKeyValue}`)

    const response = await fetch(`${window._env_.LEADFEEDER_GOLDENCOPY}/accounts/get-all-accounts?apiToken=${apiKeyValue}`, {

          method: 'GET',
          credentials: 'include'
          
    })

    const result = await response.json()
  
    // console.log("wesbites from response", result);

    if(result?.data?.data){
      let websiteObj = []
    
      for(let ele of result?.data?.data){
        websiteObj.push({website: ele?.attributes?.name, id: ele?.id})
      }
  
      // console.log("websiteObj", websiteObj);
  
      setAllWebsites(websiteObj)

      toast.success(<Alert severity="success">Authentication successful</Alert>,  {
        closeOnClick: true,
        autoClose: true,
        closeButton: true,
        newestOnTop: true
    })
    }
    else{
      toast.error(<Alert severity="error">{result?.data?.errors[0]?.title}</Alert>, {
        closeOnClick: true,
        autoClose: true,
        closeButton: true,
        newestOnTop: true
    });
    }

  }

  const handleSelectWebsiteChange = (event) => {
    const {
      target: { value },
    } = event;
    
    setSelectedWebsite(
      // On autofill we get a stringified value.
      value
    );

    // console.log("event from selectedWebsite", value)
    
    let websiteIds = []

    for(let ele of value){
      websiteIds.push(ele?.id)
    }

    // console.log("websiteids", websiteIds);

    setSelectedWebsiteIds(websiteIds)

  }

  // const [isLoading, setIsLoading] = useState(false)

  // let query = useQuery();
  // let [page, setPage] = useState(props?.page || "");

  // useEffect(() => {


  //     // localStorage.setItem('b2_code_sent', 'success');
  //   // api should be called

  // });
  if(isLoading) return <CircularProgress style={{position:"absolute",top:"45%",left:"45%"}} />
  return (
    <React.Fragment>
      {/* dialog for leadfeeder */}
      <Dialog className="leadfeederDialog" open={leadfeederDialog}>
        <DialogTitle>
          <div className="closeButton" onClick={handleLeadFeederClose} style={{cursor: "pointer"}}><svg width="12" height="12" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z" fill="#030303" fill-opacity="0.6" />
          </svg>
          </div>
          <div style={{ marginLeft: "15px" }}>LeadFeeder</div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Leadfeeder gives you the ability to track leads based on website visits, which will help track campaign effectiveness. We can also see Accounts that are interested in engaging and much more.
          </DialogContentText>

{/* 
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={hasApiKey}
              onChange={handleRadioChange}
            >
              <FormControlLabel value={true} control={<Radio size="small" />} label="I have a LeadFeeder Account" />
              <FormControlLabel value={false} control={<Radio size="small" />} label="No use Bamboobox LeadFeeder" />
            </RadioGroup>
          </FormControl> */}

          <div>
            {hasApiKey === "true" &&
              <div>
                <p style={{ marginTop: "0px", fontSize: "14px", fontWeight: "600" }}>Enter Api key</p>
                <div className="authentication_wrapper">

                  <TextField
                    id="outlined-helperText"
                    label=""
                    placeholder="Sample Input Format"
                    size="small"
                    fullWidth
                    onChange={(event)=>{
                      console.log("waht a callback", event.target.value)
                      setApiKeyValue(event.target.value)
                    }}
                  />
                  <Button style={{background:  !apiKeyValue ? "#00000061" : "#21da8c"}} className="authenticate" 
                  onClick={()=>{
                    if(apiKeyValue){
                      handleLeadAuthenticate()
                    }
                  }}>Authenticate</Button>
                </div>

                <div className="contactsupport">Contact your Customer Success POC to get your API key</div>

                <div>
                <p style={{ marginTop: "0px", fontSize: "14px", fontWeight: "600" }}>Websites</p>
                  <Select
                    style={{background: (allWebsites.length ? "" : "#E8E8E8")}}
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    displayEmpty
                    value={selectedWebsite}
                    onChange={handleSelectWebsiteChange}
                    // input={<OutlinedInput label="select"/>}
                    renderValue={(selected) => {
                      console.log("selected from renderValue", selected)
                      if (selected.length === 0) {
                        return <em>Select the websites you want to track</em>;
                      }
                      let arr = []
                      selected.map((ele) => {
                        arr.push(ele?.website)
                      })
                      return arr.join(",")
                    }}
                    // MenuProps={MenuProps}
                    fullWidth={true}
                    disabled={allWebsites.length ? false : true}
                    size="small"
                    
                  >       
                    {allWebsites.map((ele) => (
                      <MenuItem key={ele} value={ele}>
                        <Checkbox checked={selectedWebsite.indexOf(ele) > -1} />
                        <ListItemText primary={ele.website} />
                      </MenuItem>
                    ))}
                  </Select>
                </div>

              </div>
            }
            {/* {hasApiKey === "false" && <span>please contact your customer success Representative to activate the Service</span>} */}
          </div>


        </DialogContent>
        <DialogActions>
          <Button className="cancelButton" onClick={handleLeadFeederClose}>Cancel</Button>
          <Button style={{background:  !selectedWebsite.length ? "#00000061" : "#21da8c", marginRight: "16px"}} className="integrationButton" 
          onClick={()=>{
            if(selectedWebsite.length){
              handleLeadSave()
            }
            }}>Save</Button>
        </DialogActions>
      </Dialog>

      {
        ((props.tabVal == 0 || props.tabVal == 1)&&!ispresent.some(e=>e.toLowerCase()==="crm"))
          ?
          <div>
            <h4 className={classes.tool}>CRM</h4>
            <div style={{ display: 'flex' }}>
              <CustomCard ispresent={ispresent} img='0' title="Zoho" postKey="CRM" notifications={notifications} />
              <CustomCard ispresent={ispresent} img='1' title="Salesforce" postKey="CRM" notifications={notifications} />
              <CustomCard ispresent={ispresent} img='2' title="Hubspot" postKey="CRM" notifications={notifications} />
            </div>
          </div>
          :
          ""
      }
      {
        ((props.tabVal == 0 || props.tabVal == 2)&&!ispresent.some(e=>e.toLowerCase()==="intent data"))
          ?

          <div>
             <h4 className={classes.tool}>INTENT DATA</h4>
            <div style={{ display: 'flex' }}>
              <CustomCard ispresent={ispresent} img='3' title="Bombora" postKey='MAP' notifications={notifications} />
              <CustomCard ispresent={ispresent} img='4' title="Leadsift" notifications={notifications} />
              <CustomCard ispresent={ispresent} img='11' title="Leadfeeder" postKey="Intent" notifications={notifications} setLeadFeederDialog={setLeadFeederDialog} intenttoolsIntegrated={intenttoolsIntegrated} setIntentToolsIntegrated={setIntentToolsIntegrated}/>
            </div>
          </div>
          :
          ""
      }
      {
        ((props.tabVal == 0 || props.tabVal == 3) && !ispresent.some(e=>e.toLowerCase()==="MARKETING AUTOMATIONS"))
          ?
          <div>
            <h4 className={classes.tool}>MARKETING AUTOMATIONS</h4>
            <div style={{ display: 'flex',flexWrap: 'wrap' }}>
              <CustomCard ispresent={ispresent} img='5' title="Marketo" postKey="MAP" notifications={notifications} />
              <CustomCard ispresent={ispresent} img='6' title="Mailchimp" postKey="MAP" notifications={notifications} />
              <CustomCard ispresent={ispresent} img='7' title="LinkedIn" postKey="MAP" notifications={notifications} />
              <CustomCard ispresent={ispresent} img='8' title="Nextroll" postKey='MAP' notifications={notifications} />
              <CustomCard ispresent={ispresent} img='9' title="Outreach" postKey="MAP" notifications={notifications} />
              <CustomCard ispresent={ispresent} img='14' title="GoogleAds" postKey="MAP" notifications={notifications} />
              <CustomCard ispresent={ispresent} img='12' title="Salesloft" postKey="MAP" notifications={notifications} />  
              <CustomCard ispresent={ispresent} img='13' title="Pardot" postKey='MAP' notifications={notifications} />  
              <CustomCard ispresent={ispresent} img='10' title="Hubspot MAP" postKey="MAP" notifications={notifications} />
              <CustomCard ispresent={ispresent} img='15' title="ZohoCampaigns" postKey="MAP" notifications={notifications} /> 
              <CustomCard ispresent={ispresent} img='16' title="ZohoMap" postKey="MAP" notifications={notifications} />          
         
            </div>
            {/* <div style={{ display: 'flex', marginTop: '1%', }}>
              
            </div> */}
          </div>
          :
          ""
      }
      {
        (alert_access && (props.tabVal == 0 || props.tabVal ==4) && !ispresent.some(e=>e.toLowerCase()==="ALERT TOOLS"))
          ?
          <div>
            <h4 className={classes.tool}>ALERT TOOLS</h4>
            <div style={{ display: 'flex',flexWrap: 'wrap' }}>      
              <CustomCard ispresent={ispresent} img='17' title="slack" postKey="MAP" notifications={notifications} />          
         
            </div>
            {/* <div style={{ display: 'flex', marginTop: '1%', }}>
              
            </div> */}
          </div>
          :
          ""
      }


    </React.Fragment >
  );
}

export default Integration;