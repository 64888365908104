import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, Link, Navigate } from "react-router-dom";
import CircularProgress from '@material-ui/core/CircularProgress';
import { useAuthState } from "@bamboobox/b2logincheck";
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { Check, InfoOutlined } from "@material-ui/icons";
import { Box, makeStyles } from "@material-ui/core";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}


const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: '1.3rem',
    width: 200,
    height: "10vh"
  },

  instructionFrame: {
    position: 'absolute',
    width: '20vw',
    height:"60vh",
    marginTop:"50px",
    right: '5%',
    top: '14%',

    /* other / stroke */

    border: '1px solid rgba(0, 0, 0, 0.23)',
    boxSizing: 'border-box',
    borderRadius: '8px',
  },
  headingFrame: {
    /* Frame 913 */
    /* Auto layout */

    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '0px',

    position: 'relative',
    // height: '2vh',
    left: '5%',
    top: '5%',

  },
  InfoOutlined: {
    color: "#21DA8C!important",
    "& .css-1ps6pg7-MuiPaper-root": {
      color: "#21DA8C!important"
    }
  },
  heading: {
    /* Instruction */

    position: 'relative',
    left: '2%',
    top: '5%',

    /* subtitle1 */

    fontFamily:'Nunito Sans !important',
    fontFtyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '175%',
    /* identical to box height, or 28px */

    letterspacing: '0.15px',

    /* text/primary */

    color: 'rgba(0, 0, 0, 0.87)',


    /* Inside auto layout */

    flex: 'none',
    order: '1',
    flexGrow: '0',
    margin: '0px 8px',
  },

  instructionText: {
    position: 'relative',
    fontFamily:'Nunito Sans !important',

    left: '5%',
    top: '7%',
    paddingRight: '10%',

    /* body1 */

    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '150%',
    /* or 21px */

    letterSpacing: '0.15px',

    /* text/primary */

    color: 'rgba(0, 0, 0, 0.87)',
  }
}));

function Slack() {
  const userDetails = useAuthState();
  const tenantId = userDetails.user.tenantId;
  const navigate = useNavigate();
  // const [isLoading, setIsLoading] = useState(false)

  const [style, setStyle] = useState({ display: 'none' });

  let query = useQuery();
  let [status, setStatus] = useState(query.get("status") || "");

  useEffect(() => {
    if (status == "failed") {
      alert("Authentication Failed");
    }
  }, [status]);


  const classes = useStyles();
  const [externalPopup, setExternalPopup] = useState(null);

  useEffect(() => {
    if (!externalPopup) {
      return;
    }

    const timer = setInterval(() => {
      if (!externalPopup) {
        timer && clearInterval(timer);
        return;
      }
      try {
        let currentUrl = externalPopup.location.href;
        if (!currentUrl) {
          return;
        }
        currentUrl = currentUrl.replace('/#', '');
        currentUrl = new URL(currentUrl);
        const login_success = new URLSearchParams(currentUrl.search).get("status");

        // console.log("code External Popup", status, login_success);
        // console.log(" external Popup currnentUrl", typeof (currentUrl), currentUrl);
        if (login_success) { // || String(currentUrl).includes("bamboobox.in")
          // console.log("External Popup this is printing");

          // console.log(`external Popup The popup URL has URL code param = ${login_success}`);
          externalPopup.close();

          // clear timer at the end
          setExternalPopup(null);
          setStatus(login_success);
          timer && clearInterval(timer);

        }
      }
      catch (e) {
        console.log("external Popup", e);
        return;
      }
    }, 100)
  },
    [externalPopup]
  );


  function handleAuth() {

    const width = 1000;
    const height = 900;

    let url = `https://slack.com/oauth/v2/authorize?client_id=${window._env_.SLACK_CLIENT_ID}&scope=${window._env_.SLACK_SCOPE}&state=${tenantId}&redirect_uri=${window._env_.SLACK_BACKEND}/auth/redirect/b2`
    const left = window.screenX + (window.outerWidth - width) / 2;
    const top = window.screenY + (window.outerHeight - height) / 2.5;
    const title = `Slack Auth`;

    const popup = window.open(url, title, `width=${width},height=${height},left=${left},top=${top}`);
    setExternalPopup(popup);
  }
  return (
    <React.Fragment>

      <span style={{ display: "flex", padding: "0px 0px 0px 0px", marginTop: "19px", marginLeft: "32px" }}>
        <ArrowBackIosNewIcon sx={{ color: "#37CAD3", cursor: "pointer" }} onClick={() => { navigate('/') }} />
        <Typography variant="Ag" component="h2" gutterBottom sx={{ fontStyle: "nunito sans", fontSize: "16px", fontWeight: "600" }} >Slack</Typography>
      </span>
      <Stack spacing={3} direction="row" style={{ margin: "0 auto", paddingLeft: "40%", marginTop: "10vh" }}>
        {status !== "success" ?
          <Button style={{ background: "#21DA8C", width: "143px", borderRadius: "18px", color: "#FFFFFF", fontWeight: 700, fontFamily: "nunito sans", fontStyle: "bold", fontSize: "12px", height: "32px", marginLeft: "11px" }} variant="contained" disabled={(status === "success" || status === "authenticated")} onClick={() => handleAuth()} startIcon={<Check />}>
            Authenticate
          </Button> :
          <Typography>
            Integration Successful
          </Typography>
        }

      </Stack>
      <div className={classes.instructionFrame}>
        <div className={classes.headingFrame}>
          <InfoOutlined className={classes.InfoOutlined} />
          <p className={classes.heading}>
            Instruction
          </p>
        </div>
        <p className={classes.instructionText}>To authenticate your Slack workspace, click the "Authenticate" button. This will prompt you to select a Slack channel in your workspace, where alerts will be sent. Once selected, grant the necessary permissions for integration. This ensures smooth communication between Bamboobox and Slack, enabling seamless delivery of updates to the chosen channel.
        </p>
      </div>

    </React.Fragment>
  );
}

export default Slack;
